import DelayedSlide from './DelayedSlide';

interface SlideListProps {
    fromRight?: boolean;
    children: JSX.Element[];
}
function SlideList(props: SlideListProps) {
    return <div>
        { props.children.map((child, i) => {
            return <DelayedSlide fromRight={props.fromRight}>
                {child}
            </DelayedSlide>
        })}
    </div>
}

export default SlideList;
