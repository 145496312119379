import styled from 'styled-components';
import { keyframes } from 'styled-components';
import theme from 'shared/theme'

interface DurationProps { duration: number, delay?: number }
interface ColorProps { color?: string }

export const OffsetH1 = styled.h1`
  @media ${theme.size.tablet} {
    margin-top: 200px;
  }
`

export const Page = styled.div`
  background-color: #282c34;
  color: ${theme.colors.white};
  overflow-x: hidden;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  min-height: 98vh;
`

export const Body = styled.div`
  width: 100%;
  font-size: calc(10px + 2vmin);
`

const FadeInAnimation = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

export const Content = styled.p`
  letter-spacing: 1px;
`

export const ContentSection = styled.div<ColorProps>`
  position: relative;
  padding: 10vh 0;
  background-color: ${props => props.color ? props.color: 'grey'};
`

export const FadeIn = styled.div<DurationProps>`
  opacity: 0%;
  animation: ${FadeInAnimation} ${props => props.duration}s ease;
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: forwards;
`

export const Img = styled.img`
  border-radius: 7px;

  max-height: 70vh;
  max-width: 80vw;
  @media ${theme.size.tablet} {
    max-width: 40vw;
  }
`
export const ColumnContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;
`