const theme = {
    colors: {
        white: '#FFFFFF'
    },
    typography: {

    },
    size: {
        mobileS: '(min-width: 320px)',
        mobileM: '(min-width: 375px)',
        mobileL: '(min-width: 425px)',
        tablet: '(min-width: 768px)',
        laptop: '(min-width: 1024px)',
        laptopL: '(min-width: 1440px)',
        desktop: '(min-width: 2560px)',
    },
    links: {
        github: 'https://github.com/tylerslaton',
        blog: 'https://medium.com/@mtslaton1',
        linkedin: 'https://www.linkedin.com/in/michael-t-slaton/',
        facebook: 'https://www.facebook.com/michael.t.slaton',
    }
}
   

export default theme;