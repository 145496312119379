import { BobAnimation } from './style'
import { AiOutlineArrowDown } from 'react-icons/ai';
import { useEffect, useState } from 'react';


function BouncingArrow() {
    const [currentScrollHeight, setCurrentScrollHeight] = useState(0)
    const [atBottom, setAtBottom] = useState(false)

    useEffect(() => {
        const onScroll = (e: any) => {
            setCurrentScrollHeight(e.target.documentElement.scrollTop + e.target.documentElement.clientHeight)
            setAtBottom(currentScrollHeight >= e.target.documentElement.scrollHeight-20)
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [currentScrollHeight, atBottom]);


    return atBottom ? <></> : <BobAnimation><AiOutlineArrowDown/></BobAnimation>;
}

export default BouncingArrow;
