import styled, {css} from 'styled-components';
import theme from 'shared/theme'
import {Link} from 'react-router-dom'

interface ToggleProps {
  toggled: boolean;
}

export const NavBar = styled.div`
  width: 100%;

  z-index: 2;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: auto;
`

export const Branding = styled.div<ToggleProps>`
  z-index: 2;
  margin: 20px;
  font-size: 30px;
  color: white;
  transition: 0.45s;
  background-color: rgba(169, 169, 169, 0.9);
  padding: 5px 15px 5px 15px;
  border-radius: 10px;

  visibility: ${props => (props.toggled ? "hidden": 'visible')};
  opacity: ${props => props.toggled ? "0": "1"};
`

export const Toggle = styled.div`  
  z-index: 2;
  height: 40px;
  cursor: pointer;
  padding: 5px 15px 5px 15px;

  margin: 20px;
  font-size: 20px;
`

export const NavMenu = styled.div<ToggleProps>`
  position: fixed;

  top: 0;
  bottom: auto;

  width: 100%;
  height: ${props => (props.toggled ? '100%': '0%')};

  background-color: rgb(169, 169, 169);
  z-index: 1;
  transition: 0.35s;
  overflow-y: hidden;
  opacity: 0.95;
  margin: auto;

  display: flex;
  flex-direction: column-reverse;
  align-content: stretch;
  @media ${theme.size.tablet} {
    flex-direction: row;
  }
`

export const NavMenuGroup = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  space-between: 20px;

  transition: 0.1s;
  visibility: ${props => (props.toggled ? "visible": 'hidden')};
  opacity: ${props => props.toggled ? "1": "0"};

  margin: auto;
  gap: 5vh;

  width: inherit;
  height: 30%;
`

const sharedLinkStyle = css`
  text-decoration: none;
  color: black;
  font-weight: bold;

  &:hover {
    color: gray;
  }

  font-size: 30px;
  @media ${theme.size.tablet} {
    font-size: 50px;
  }
`

export const StyledRouterLink = styled(Link)`
  ${sharedLinkStyle}
`

export const StyledLink = styled.a`
  ${sharedLinkStyle}
`