import styled, {keyframes, css} from 'styled-components';

interface AnimateProps { toggle?: boolean, fromRight?: boolean }

export const SlideIn = styled.div<AnimateProps>`
    ${props => props.fromRight ? RightStartingPosition : LeftStartingPosition};
    position: relative;
    ${props => props.toggle ? props.fromRight ? SlideInAnimationRight : SlideInAnimationLeft : ``}
`

/*
    Slide From Right Animation
*/
const RightStartingPosition = css`
    right: -100vw;
`

const SlideKeyframesRight = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(-100vw) }
`

const SlideInAnimationRight = css`
    animation: ${SlideKeyframesRight} ease 0.75s; 
    animation-fill-mode: forwards;
`

/*
    Slide From Left Animation
*/
const LeftStartingPosition = css`
    left: -100vw;
`

const SlideKeyframesLeft = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(100vw) }
`

const SlideInAnimationLeft = css`
    animation: ${SlideKeyframesLeft} ease 0.75s; 
    animation-fill-mode: forwards;
`