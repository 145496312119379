import { SlideIn } from './style'
import { useEffect, useRef, useState } from 'react';

interface DelayedSlideProps{
    fromRight?: boolean;
    children: JSX.Element;
}
function DelayedSlide(props: DelayedSlideProps) {
    const [isVisible, setIsVisible] = useState(false)

    const containerRef = useRef(null)
    const callBackFunction = (entries: any) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: .5,
        }
        
        const observer = new IntersectionObserver(callBackFunction, options)
        const currentRef = containerRef.current
        if (currentRef) {
            if (isVisible){
                observer.unobserve(currentRef)
            } else {
                observer.observe(currentRef)
            }
        }

        return () => {
            if(currentRef) observer.unobserve(currentRef)
        }
    }, [containerRef, isVisible])

    return <div ref={containerRef}>
        <SlideIn toggle={isVisible} fromRight={props.fromRight}>
            {props.children}
        </SlideIn>
    </div>
}

export default DelayedSlide;
