import { Wrapper, SlideIn, FadeIn } from './style'
import { ReactElement, useEffect, useRef, useState } from 'react';


interface SlideInProps {
    fromRight?: boolean;
    slideElement: ReactElement;
    fadeElement: ReactElement;
}
function SlideAndFade(props: SlideInProps) {
    const [isVisible, setIsVisible] = useState(false)

    const containerRef = useRef(null)
    const callBackFunction = (entries: any) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: .5,
        }
        
        const observer = new IntersectionObserver(callBackFunction, options)
        const currentRef = containerRef.current
        if (currentRef) {
            if (isVisible){
                observer.unobserve(currentRef)
            } else {
                observer.observe(currentRef)
            }
        }

        return () => {
            if(currentRef) observer.unobserve(currentRef)
        }
    }, [containerRef, isVisible])


    return <Wrapper ref={containerRef} fromRight={props.fromRight}>
        <SlideIn toggle={isVisible} fromRight={props.fromRight}>
            {props.slideElement}
        </SlideIn>
        <FadeIn toggle={isVisible} fromRight={props.fromRight}>
            <div style={{margin: 'auto'}}>
                {props.fadeElement}
            </div>
        </FadeIn>
    </Wrapper>
}

export default SlideAndFade;
