import {Wrapper} from './style'
import Router from "components/Router"
import NavBar from "components/Nav"
 
function App() {
  return (
    <Wrapper className="App">
      <NavBar/>
      <Router/>
    </Wrapper>
  );
}

export default App;
