import {Toggle, NavBar, NavMenuGroup, NavMenu, StyledLink, Branding} from './style';
import {useState} from 'react';
import theme from 'shared/theme';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import {Link} from 'react-router-dom'


function Nav() {
  const [toggled, setToggled] = useState(false)

  const toggleMenu = () => {
    setToggled(!toggled)
  }

  return (
    <NavBar>
      <Branding toggled={toggled}>
        <Link style={{color: 'white', textDecoration: 'none'}} to='/'>MTS</Link>
      </Branding>
      <Toggle onClick={toggleMenu}>
        { toggled ?
          <AiOutlineClose color='black' size='100%'/> :
          <AiOutlineMenu color='white' size='100%'/>
        }
      </Toggle>
      <NavMenu toggled={toggled}>
        <NavMenuGroup toggled={toggled}>
          <StyledLink href={theme.links.github} target='_blank'>Github</StyledLink>
          <StyledLink href={theme.links.blog} target='_blank'>Blog</StyledLink>
          <StyledLink href={theme.links.linkedin} target='_blank'>LinkedIn</StyledLink>
          <StyledLink href={theme.links.facebook} target='_blank'>Facebook</StyledLink>
        </NavMenuGroup>
      </NavMenu>
    </NavBar>
  );
}

export default Nav;
