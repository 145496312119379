import BouncingArrow from 'components/BouncingArrow'
import SlideAndFade from 'components/SlideAndFade'
import SlideList from 'components/SlideList'
import mountainPicture from 'static/images/mountain.jpg';
import hackathonPicture from 'static/images/hackathon.jpg';
import {WaveBot, CircleTop} from 'components/Wave'
import {
  Page, 
  Header, 
  Body, 
  FadeIn, 
  Img, 
  ContentSection,
  ColumnContent,
  OffsetH1,
  Content,
} from './style'

function Home() {
  return (
    <Page>
      <Header>
        <FadeIn duration={1} delay={0.5}>
          <h1>Hi, I'm Michael</h1>
        </FadeIn>
        <FadeIn duration={1} delay={1.5}>
          <p>... but I go by Tyler. It's a long story.</p>
        </FadeIn>
        <BouncingArrow/>
        <WaveBot/>
      </Header>
      <Body>
        <ContentSection>
          <SlideAndFade
              slideElement={<Img src={hackathonPicture} alt="hackathon" />}
              fadeElement={
                <div>
                  <h1>Programming is my passion</h1>
                  <Content>
                    I've been working with and around technology for as long as I can remember. 
                    Everywhere from building computers to telling them how to serve the website you're 
                    reading right now - I am extremely passionate about technology.
                  </Content>
                  <Content>
                    Currently I find myself working at Acorn Labs, a cloud start-up with the goal of making Kubernetes
                    easier and more accessible for developers looking to deploy their applications to it.
                    In addition to this, I work as a technology mentor for Road to Hire, a non-profit that helps
                    young adults find sustainable careers in technology.
                  </Content>
                </div>
              }
            />
        </ContentSection>
        <ContentSection>
          <SlideAndFade
            fromRight={true}
            slideElement={<Img src={mountainPicture} alt="mountain" style={{zIndex: 2000}}/>}
            fadeElement={
              <div>
                <h1>... but I also have many others!</h1>
                <Content>
                  In the last few years I've really be able to take some time and figure out
                  what sort of things in life I'm excited by. So far the main things have been 
                  cooking, traveling, reading, video games, and indoor rock climbing.
                </Content>
              </div>
            }
          />
        </ContentSection>
        <ContentSection color="#282c34">
          <CircleTop/>
          <OffsetH1>Areas of expertise</OffsetH1>
          <p style={{marginLeft: "20%", marginRight: "20%"}}>
            My time in the industry has allowed me to interact with many different technologies.
            Like most developers, the sheer amount of these technologies prevents me from knowing
            all of them. This logically leads to areas of expertise. Over the course of my career
            I've picked up skill from both professional and personal experience.
          </p>
          <ColumnContent>
            <div style={{}}>
              <h3>Professional</h3>
              <SlideList>
                <p>Golang</p>
                <p>Kubernetes</p>
                <p>Terraform</p>
                <p>AWS</p>
                <p>TypeScript</p>
                <p>Python</p>
              </SlideList>
            </div>
            <div style={{}}>
              <h3>Personal</h3>
              <SlideList fromRight={true}>
                <p>React</p>
                <p>React Native</p>
                <p>Expo</p>
                <p>GCP</p>
                <p>Rust</p>
                <p>NestJS</p>
              </SlideList>
            </div>
          </ColumnContent>
        </ContentSection>
      </Body>
    </Page>
  );
}

export default Home;
