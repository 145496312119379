import styled from 'styled-components';
import theme from 'shared/theme'

interface ColorProps { color?: string }


/*
    Wave Top
*/
export const WaveTopDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
`

export const WaveTopSVG = styled.svg`
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 350px;
`

/*
    Wave Bottom
*/
export const WaveBotDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

`

export const WaveBotSVG = styled.svg`
    position: relative;
    display: block;
    width: calc(200% + 1.3px);
    height: 250px;
    @media ${theme.size.tablet} {
        width: calc(146% + 1.3px);
        height: 350px
    }
`

/*
    Circle Top
*/
export const CircleTopDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
`

export const CircleTopSVG = styled.svg`
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    @media ${theme.size.tablet} {
        width: calc(120% + 1.3px);
        height: 197px;
    }
`

/*
    Shared
*/
export const Path = styled.path<ColorProps>`
  fill: ${props => props.color ? props.color: '#282c34'};
`