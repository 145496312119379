import styled, {keyframes, css} from 'styled-components';
import theme from '../../../src/shared/theme'

interface AnimateProps { toggle?: boolean, fromRight?: boolean }

const BoxSize = css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    @media ${theme.size.tablet} {
        max-width: 40%;
    }
`

export const Wrapper = styled.div<AnimateProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @media ${theme.size.tablet} {
        flex-direction: ${props => props.fromRight ? 'row-reverse' : 'row'};;
        justify-content: space-evenly;
    }
`

export const SlideIn = styled.div<AnimateProps>`
    ${props => props.fromRight ? RightStartingPosition : LeftStartingPosition};
    ${BoxSize}
    position: relative;
    ${props => props.toggle ? props.fromRight ? SlideInAnimationRight : SlideInAnimationLeft : ``}
`


export const FadeIn = styled.div<AnimateProps>`
    opacity: 0%;
    ${BoxSize}
    ${props => props.toggle ? FadeInAnimation: ``}
    margin: 0 50px 0 50px;
`

/*
    Fade In Animation
*/
const FadeKeyframes = keyframes`
    from { opacity: 0% }
    to { opacity: 100% }
`

const FadeInAnimation = css`
    animation: ${FadeKeyframes} ease 1s; 
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

/*
    Slide From Right Animation
*/
const RightStartingPosition = css`
    right: -100vw;
`

const SlideKeyframesRight = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(-100vw) }
`

const SlideInAnimationRight = css`
    animation: ${SlideKeyframesRight} ease 1s; 
    animation-fill-mode: forwards;
`

/*
    Slide From Left Animation
*/
const LeftStartingPosition = css`
    left: -100vw;
`

const SlideKeyframesLeft = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(100vw) }
`

const SlideInAnimationLeft = css`
    animation: ${SlideKeyframesLeft} ease 1s; 
    animation-fill-mode: forwards;
`