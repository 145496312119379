import styled, {keyframes} from 'styled-components';

const Bob = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(12px);
  }
`

export const BobAnimation = styled.div`
    margin: auto;
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    animation: ${Bob} infinite 1s alternate;
    z-index: 2;
`
